<div class="banners">
  <div class="banner clutch">
    <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="2" data-height="45"
      data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="2186085"></div>
  </div>
  <div class="banner goodfirms">
    <div class="goodfirm-widget" data-widget-type="goodfirms-widget-t8" data-widget-pattern="poweredby-basic"
      data-height="60" data-company-id="138897"></div>
  </div>
  <div class="banner designrush">
    <a href="https://www.designrush.com/agency/profile/el-pixel" target="_blank" rel="El Pixel">
      <div class="rush-img"></div>
    </a>
  </div>
</div>
