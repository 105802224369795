<div class="container">
  <div class="wrapper">
    <div class="content">
      <div class="label">
        <h2 i18n="@@Our Clients Speak">Our Clients Speak<br></h2>
      </div>
      <div class="assessments">
        <app-slider [slidesCount]="1" [reviews]="reviews"></app-slider>
      </div>
    </div>
  </div>
  <div class="banners">
    <app-banners></app-banners>
  </div>
</div>
