import { AsyncPipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { Settings } from '@interfaces/settings.interface';
import { Observable, of } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-statistic',
  standalone: true,
  imports: [MatIconModule, RouterModule, AsyncPipe],
  templateUrl: './statistic.component.html',
  styleUrl: './statistic.component.scss',
})
export class StatisticComponent {
  public showButton = input<boolean>();
  public teamPhotoUrl = environment.staticUrl + '/resources/pixel/images/team-image.jpg';

  constructor(private dataService: DataService){}

  public statisticInfoHome: Observable<Settings | null> = of(null);
  public statisticInfoCompany: Observable<Settings | null> = of(null);

  ngOnInit(): void {
    this.statisticInfoCompany = this.dataService.getFilteredStatistic('company');
    this.statisticInfoHome = this.dataService.getFilteredStatistic('home');
  }
}
