<div class="statistic">
  <div class="statistic__experience">
    <div class="experience-info">
      <div class="text">
        <h2 i18n="@@Team of Professionals">Team of Professionals</h2>
        <p class="l normal" i18n="@@Committed to delivering quality, affordability, and results, our dedicated team empowers our clients to thrive in the digital era with">
          Committed to delivering quality, affordability, and results, our
          dedicated team empowers our clients to thrive in the digital era with
        </p>
        <div class="text__slogan">
          <h3 i18n="@@*span*Honestly*span* in every pixel"><span>Honestly</span> in every pixel</h3>
          <h3 i18n="@@*span*experience*span* in every app"><span>experience</span> in every app</h3>
        </div>
      </div>
      @if(showButton()){
        <button class="button-link" routerLink="/company" i18n="@@More about us">
          More about us
          <mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon>
        </button>
      }
    </div>
    <div class="cubes-info">
      <p class="l bold" i18n="@@5+ years of experience">5+ years of experience<br></p>
      <p class="l bold" i18n="@@3-5 years of experience">3-5 years of experience<br></p>
      <p class="l bold" i18n="@@&gt;3 years of experience">&gt;3 years of experience<br></p>
    </div>
  </div>
  <div class="statistic__info">
    @if(showButton()){
      <div class="info-wrapper">
        @if(statisticInfoHome | async; as statistic){
          @for(statistic of statistic.settings.data; track statistic){
            <div class="info-block">
              <h1>{{statistic.field_value}}</h1>
              <p class="l normal">{{statistic.field_name}}</p>
            </div>
          }
        }
      </div>
    }
    @else {
      <div class="info-wrapper">
        @if(statisticInfoCompany | async; as statistic){
          @for(statistic of statistic.settings.data; track statistic){
            <div class="info-block">
              <h1>{{statistic.field_value}}</h1>
              <p class="l normal">{{statistic.field_name}}</p>
            </div>
          }
          <div class="info-block">
            <h2 i18n="@@launching own product">
              launching
              <br>
              own
              <br>
              product
            </h2>
          </div>
        }
      </div>
    }

  </div>
  <div class="statistic__photo border-custom big">
    <div
      class="image"
      [style.background-image]="
          'url(' + teamPhotoUrl + ')'
        "
    ></div>
  </div>
</div>
