import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BannersComponent } from '@components/banners/banners.component';
import { SliderComponent } from '@components/slider/slider.component';
import { Reviews } from '@interfaces/reviews.interface';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-clients-speak',
  standalone: true,
  imports: [BannersComponent, MatIconModule, SliderComponent],
  templateUrl: './clients-speak.component.html',
  styleUrl: './clients-speak.component.scss',
})
export class ClientsSpeakComponent {
  constructor(private dataService: DataService) {}

  public reviews: Observable<Reviews[]> | null = null;

  ngOnInit(): void {
    this.reviews = this.dataService.reviews;
  }
}
