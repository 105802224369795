import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banners',
  standalone: true,
  imports: [],
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
})
export class BannersComponent implements OnInit, AfterViewInit {
  constructor() {}
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    // @ts-ignore
    if (window.GOODFIRMS) window.GOODFIRMS.Init();
    // @ts-ignore
    if (window.CLUTCHCO) window.CLUTCHCO.Init();
  }
}
